import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const FeedItems = makeShortcode("FeedItems");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "qa--life"
    }}>{`QA > Life`}</h1>
    <p>{`On this page we document some of the things leading a quality infused life has taught us. This is not to say we've always led a quality infused life. Sometimes missing out on quality has shown us of ways to get back to quality and reminded us to embrace quality as often and as frequently as possible. `}</p>
    <h2 {...{
      "id": "quality-infused--life"
    }}>{`Quality Infused >>> Life`}</h2>
    <FeedItems filterByTags={['qa-to-life']} mdxType="FeedItems" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      